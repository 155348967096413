<template>
	<div>
		<!-- Title and Logo -->
		<h-retribusi />
		<!-- end Logo and Title -->
		<panel title="List Users" class="panel panel-success">
			<!-- Tools -->
			<div class="pl-4 py-2 border shadow-sm">
				<span>
					<b-button variant="primary" to="/users/users/tambah"><i class="fa fa-plus pr-1"></i> Tambah Users
					</b-button>
				</span>
				<span class="px-2">
					<b-button variant="primary" @click="reload"><i class="fa fa-redo-alt pr-2"></i> Refresh</b-button>
				</span>
			</div>
			<!-- end of Tools -->

			<!-- Table -->
			<!-- Filter -->
			<b-row>
				<b-col md="6" class="mb-3">
					<b-form-group :label-cols="3" label="Filter By" class="mb-0 my-1">
						<b-input-group size="md">
							<b-form-select v-model="filterBy" :options="fieldOptions" @change="changeFilterBy()">
								<option slot="first" :value="'all'">All Data</option>
							</b-form-select>
						</b-input-group>
					</b-form-group>
					<b-form-group :label-cols="3" label="Filter" class="mb-0"
						description="Type to Search or Click Clear to Stop Searching ">
						<b-input-group size="md">
							<b-form-input v-model="filter" @keyup.enter="testFunc(filter)" placeholder="Type to Search"
								debounce="500" />
							<b-input-group-append>
								<b-btn :disabled="!filter" @click="filter = ''" variant="info">Clear</b-btn>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col md="6" class="mt-1">
					<b-form-group :label-cols="3" label="Sort" class="mb-0 my-1">
						<b-input-group size="md">
							<b-form-select v-model="sortBy" :options="fieldOptions">
								<option slot="first" :value="null">-- none --</option>
							</b-form-select>
							<b-form-select :disabled="!sortBy" v-model="sortDesc" slot="append">
								<option :value="false">Asc</option>
								<option :value="true">Desc</option>
							</b-form-select>
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>
			<!-- end filter section -->
			<!-- Data Table & Pagination -->
			<b-table show-empty small id="masTable" ref="masTable" head-variant="light" :hover="true"
				:busy.sync="isBusy" :items="myGetData" :fields="fields" :current-page="currentPage" :per-page="perPage"
				:filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" responsive bordered>
				<template #cell(index)="data">
					{{ data.index + 1 }}
				</template>
				<template v-slot:table-busy>
					<div class="text-center text-danger my-2">
						<b-spinner class="align-middle"></b-spinner>
						<strong>Loading...</strong>
					</div>
				</template>

				<template v-slot:table-colgroup="scope">
					<col v-for="field in scope.fields" :key="field.key"
						:style="{ width: field.key === 'actions' ? '105px' : '' }" />
				</template>

				<template #head()="data">
					<span style="white-space: pre;">{{ data.label }}</span>
				</template>

				<!-- NIK dan NIP -->
				<template #cell(nik)="data">
					<span v-if="data.item.pegawai">
						NIK: {{ data.item.pegawai.nik }} <br>
						NIP: {{ data.item.pegawai.nip }}
					</span>
				</template>

				<!-- unit -->
				<template #cell(unit)="data">
					<span v-if="data.item.pegawai">
						{{ data.item.pegawai.unit_pd.nama }}
					</span>
				</template>

				<!-- perangkat --->
				<template #cell(perangkat)="data">
					<span v-if="data.item.pegawai">
						{{ data.item.pegawai.unit_pd.perangkat_pd.nama }}
					</span>
				</template>

				<template v-slot:cell(actions)="data">
					<b-dropdown split class="d-flex align-item-center" size="sm" variant="info">
						<template #button-content>
							<i class="fa fa-cogs pr-1"></i> Aksi
						</template>
						<b-dropdown-item @click="editButton(data.item.id)"><i class="fa fa-edit"></i> Edit
						</b-dropdown-item>
						<b-dropdown-item @click="swalNotification('error', data.item.id)"><i
								class="fa fa-trash-alt"></i> Hapus</b-dropdown-item>
					</b-dropdown>
				</template>

			</b-table>
			<!-- end of table -->

			<div class="divider"></div>

			<!-- pagination section -->
			<b-row>
				<div class="divider"></div>
				<b-col md="6" class="my-0">
					<b-form-group :label-cols="2" label="Per page" class="mb-0">
						<b-form inline>
							<b-form-select :options="pageOptions" style="width: 100px;" v-model="perPage" />
							<label class="ml-1 mr-1">/</label>
							<b-form-input :disabled="true" v-model="totalRows" style="width: 100px;"
								class="text-right" />
							<label class="ml-1">Rows</label>
						</b-form>
					</b-form-group>
				</b-col>
				<b-col md="6" class="my-0">
					<b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill"
						size="md" class="my-0 "></b-pagination>
				</b-col>
			</b-row>
			<!-- end pagination section -->
			<!-- end of Table -->
		</panel>
	</div>
</template>

<script>
	/* eslint-disable */
	// eslint-disable-next-line
	// eslint-disable vue/no-unused-vars
	// eslint-disable-line no-unused-vars
	import HRetribusi from '../../../components/npwr/HRetribusi.vue';
	import axios from 'axios'

	export default {
		components: {
			HRetribusi
		},
		data() {
			return {
				// Tools
				isBusy: false,
				sortBy: null,
				sortDesc: false,
				filter: null,
				filterBy: "all",
				pageOptions: [5, 10, 15, 25],
				perPage: 10,
				currentPage: 1,
				totalRows: 0,

				// fields
				fields: [{
						key: "index",
						label: 'No.'
					},
					{
						key: "name",
						label: 'NAMA',
						sortable: true
					},
					{
						key: "email",
						label: 'EMAIL',
						sortable: true
					},
					{
						key: "nik",
						label: 'NIK & NIP',
						sortable: true
					},
					{
						key: "unit",
						label: 'UNIT',
						sortable: true
					},
					{
						key: "perangkat",
						label: 'PERANGKAT',
						sortable: true
					},
					{
						key: "actions",
						label: 'Opsi'
					}
				]
			}
		},
		computed: {
			fieldOptions() {
				// Create an options list from our fields
				return this.fields
					.filter((f) => f.sortable)
					.map((f) => {
						return {
							text: f.label,
							value: f.key,
						};
					});
			},
		},
		methods: {
			// data table
			myGetData(ctx) {
				// ctx =
				// berasal dari tag <b-table></b-table>
				//this.$Progress.start();
				this.isBusy = true;
				let promise = axios.get("/api/manajemen/user", {
					params: {
						page: ctx.currentPage,
						perpage: ctx.perPage,
						sortby: ctx.sortBy,
						sortdesc: ctx.sortDesc,
						filter: ctx.filter,
						filterby: this.filterBy,
					},
				});
				return promise
					.then((response) => {
						const items = response.data.data;
						// Data Pagination
						// configPagination(response.data.meta);
						this.totalRows = response.data.meta.total;
						// Here we could override the busy state, setting isBusy to false
						//this.$Progress.finish();
						this.isBusy = false;
						return items;
					})
					.catch((error) => {
						// Here we could override the busy state, setting isBusy to false
						//this.$Progress.fail();
						this.isBusy = false;
						// Returning an empty array, allows table to correctly handle
						// internal busy state in case of error
						return [];
					});
			},
			reload() {
				// with id="masTable" in tag b-table
				// this.$root.$emit('bv::refresh::table', 'masTable')
				// with property ref="masTable" in tag b-table
				this.$refs.masTable.refresh();
			},
			changeFilterBy() {
				if (this.filter) {
					this.$refs.masTable.refresh();
				}
			},
			swalNotification(swalType, id) {
				var btnClass = (swalType == 'error') ? 'danger' : swalType;
				btnClass = (swalType == 'question') ? 'primary' : btnClass;
				this.$swal({
					title: 'Anda yakin ?',
					text: 'Anda tidak akan bisa mengembalikan data!',
					type: swalType,
					showCancelButton: true,
					buttonsStyling: false,
					confirmButtonText: "Hapus",
					cancelButtonText: 'Cancel',
					confirmButtonClass: 'btn m-r-5 btn-' + btnClass + '',
					cancelButtonClass: 'btn btn-default',
				}).then(result => {
					if (result.isConfirmed) {
						axios.delete('/api/manajemen/user/' + id).then(response => {
							this.$swal({
								title: "User Berhasil Dihapus!",
								icon: 'success',
								type: 'success',
								showCloseButton: true,
								showConfirmButton: false,
								timer: 5000
							})
							this.reload()
						})
					}
				})
			},
			editButton(index) {
				this.$router.push({
					name: 'EditUserId',
					params: {
						id: index
					}
				})
			}
		}
	}
</script>